enum sidebarDeliveryAdminScopes {
  FullAccess = 'LOATDeliveryWindow.App.FullAccess',
  DdcList = 'LOATDeliveryWindow.DDCList.Read',
  ClientList = 'LOATDeliveryWindow.ClientList.Read',
  ImportsExports = 'LOATDeliveryWindow.ImportsAndExports.Read',
  ImportsExportsClientUpload = 'LOATDeliveryWindow.ClientCSVUpload.Read',
  ImportsExportsClientDownload = 'LOATDeliveryWindow.ClientCSVDownload.Read',
  ImportsExportsRuleUpload = 'LOATDeliveryWindow.RuleCSVUpload.Read',
  ImportsExportsRuleDownload = 'LOATDeliveryWindow.RuleCSVDownload.Read',
  Autoblock = 'LOATDeliveryWindow.DDCAutoblock.Read',
  Audience = 'LOATDeliveryWindow.AudienceBuilder.Write',
  FeatureActivation = 'LOATDeliveryWindow.FeatureActivation.Write'
}

export { sidebarDeliveryAdminScopes };
