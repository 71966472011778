import { CategorySearch, Clipboard, List2, Users } from '@hexa-ui/icons';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { useCheckPermissionsForce } from 'hooks/useSidebarForce/hooks/useCheckPermissionsForce';
import { useMemo } from 'react';

export const ForceSidebarIcons = {
  Tasks: (): JSX.Element => <Clipboard />,
  OperationsPanel: (): JSX.Element => <CategorySearch />,
  TaskGroups: (): JSX.Element => <List2 />,
  Users: (): JSX.Element => <Users />
};

const PATHS = {
  TASKS_V2: '/frontline/tasks',
  TASKS_V1: '/force/task-management',
  OPERATIONS_PANEL: '/force/operations-panel',
  TASK_GROUPS: '/frontline/task-groups',
  AUDIENCE_BUILDER: '/audiences?origin=force'
};

const useSidebarForce = () => {
  const { formatMessage } = useIntl();
  const {
    hasVTwoCardPermission,
    hasVOneCardPermission,
    hasOperationsReadTaskPermission,
    hasTaskGroupsPermission,
    hasTaskManagementPermission
  } = useCheckPermissionsForce();

  const sidebarItems = useMemo(() => {
    const items: SidebarItem[] = [];
    if (hasVTwoCardPermission) {
      items.push({
        id: 'tasks-v2',
        title: formatMessage({ id: 'useSidebarForce.pageTasks' }),
        icon: ForceSidebarIcons.Tasks,
        path: PATHS.TASKS_V2
      });
    }

    if (hasVOneCardPermission) {
      items.push({
        id: 'tasks-v1',
        title: formatMessage({ id: 'useSidebarForce.pageTasks' }),
        icon: ForceSidebarIcons.Tasks,
        path: PATHS.TASKS_V1
      });
    }

    if (hasOperationsReadTaskPermission) {
      items.push({
        id: 'operation',
        title: formatMessage({ id: 'useSidebarForce.pageOperations' }),
        icon: ForceSidebarIcons.OperationsPanel,
        path: PATHS.OPERATIONS_PANEL
      });
    }

    if (hasTaskGroupsPermission) {
      items.push({
        id: 'task-groups',
        title: formatMessage({ id: 'useSidebarForce.pageTaskGroups' }),
        icon: ForceSidebarIcons.TaskGroups,
        path: PATHS.TASK_GROUPS
      });
    }

    if (hasTaskManagementPermission) {
      items.push({
        id: 'audience-builder',
        title: formatMessage({ id: 'useSidebarForce.pageAudienceBuilder' }),
        icon: ForceSidebarIcons.Users,
        path: PATHS.AUDIENCE_BUILDER
      });
    }

    return items;
  }, [
    formatMessage,
    hasOperationsReadTaskPermission,
    hasTaskGroupsPermission,
    hasTaskManagementPermission,
    hasVOneCardPermission,
    hasVTwoCardPermission
  ]);

  useSidebar({
    items: sidebarItems,
    utils: []
  });

  return sidebarItems;
};

export default useSidebarForce;
