/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar, Lock, Settings, SortDefault, Store, Tag } from '@hexa-ui/icons';
import { SidebarItem, useHasPermission, useSidebar } from 'admin-portal-shared-services';
import useBeesProvider from 'context/BeesContext/BeesContext';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { sidebarDeliveryAdminScopes } from './useSidebarDeliveryAdmin.types';

const useSidebarDeliveryWindow = () => {
  const { country } = useBeesProvider();
  const { formatMessage } = useIntl();

  const DDC_LIST = {
    id: 'DDCs_List_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.DdcsList' }),
    icon: () => <Store />,
    path: `/delivery-window/DDCs`
  };
  const DDC_LIST_PERMISSION = useHasPermission(
    [sidebarDeliveryAdminScopes.DdcList, sidebarDeliveryAdminScopes.FullAccess],
    'OR'
  );

  const CLIENT_LIST = {
    id: 'Delivery_Window_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.ClientsList' }),
    icon: () => <Calendar />,
    path: `/delivery-window/DeliveryWindow`
  };
  const CLIENT_LIST_PERMISSION = useHasPermission(
    [sidebarDeliveryAdminScopes.ClientList, sidebarDeliveryAdminScopes.FullAccess],
    'OR'
  );

  const IMPORTS_EXPORTS = {
    id: 'Imports_Exports_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.ImportsExports' }),
    icon: () => <SortDefault />,
    path: `/delivery-window/ImportsAndExports`
  };
  const IMPORTS_EXPORTS_PERMISSION = useHasPermission(
    [
      sidebarDeliveryAdminScopes.ImportsExportsClientUpload,
      sidebarDeliveryAdminScopes.ImportsExportsClientDownload,
      sidebarDeliveryAdminScopes.ImportsExportsRuleUpload,
      sidebarDeliveryAdminScopes.ImportsExportsRuleDownload,
      sidebarDeliveryAdminScopes.FullAccess
    ],
    'OR'
  );

  const AUTOBLOCK = {
    id: 'Autoblock_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.Autoblock' }),
    icon: () => <Lock />,
    path: `/delivery-window/DeliveryCapacityAvailabilityParameters`
  };
  const AUTOBLOCK_PERMISSION = useHasPermission(
    [sidebarDeliveryAdminScopes.Autoblock, sidebarDeliveryAdminScopes.FullAccess],
    'OR'
  );

  const AUDIENCE = {
    id: 'Audiences_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.Audiences' }),
    icon: () => <Tag />,
    path: '/audiences?origin=deliveryAdmin'
  };
  const AUDIENCE_PERMISSION = useHasPermission(
    [sidebarDeliveryAdminScopes.Audience, sidebarDeliveryAdminScopes.FullAccess],
    'OR'
  );

  const SELLER = {
    id: 'Audiences_menu_item',
    title: formatMessage({ id: 'sidebarDeliveryWindow.Seller' }),
    icon: () => <Settings />,
    path: '/delivery-window/SellerSettings'
  };
  const SELLER_PERMISSION = useHasPermission([sidebarDeliveryAdminScopes.FeatureActivation], 'OR');

  const items = useMemo(() => {
    const items: SidebarItem[] = [];

    DDC_LIST_PERMISSION && items.push(DDC_LIST);
    CLIENT_LIST_PERMISSION && items.push(CLIENT_LIST);
    IMPORTS_EXPORTS_PERMISSION && items.push(IMPORTS_EXPORTS);
    AUTOBLOCK_PERMISSION && items.push(AUTOBLOCK);
    AUDIENCE_PERMISSION && items.push(AUDIENCE);
    SELLER_PERMISSION && items.push(SELLER);

    return items;
  }, [
    country,
    DDC_LIST_PERMISSION,
    CLIENT_LIST_PERMISSION,
    IMPORTS_EXPORTS_PERMISSION,
    AUTOBLOCK_PERMISSION,
    AUDIENCE_PERMISSION,
    SELLER_PERMISSION
  ]);

  useSidebar({
    items,
    utils: []
  });

  return items;
};
export default useSidebarDeliveryWindow;
