import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  sidebar: {
    campaigns: 'Campanhas',
    audiences: 'Públicos'
  },
  audience: {
    listPage: {
      searchPlaceholder: 'Pesquisar por nome',
      audience: 'Públicos',
      loading: 'Carregando itens...',
      estimatedTarget: 'Meta atual estimada',
      createdOn: 'Criada em',
      lastUpdate: 'Atualizada em',
      campaigns: 'Campanhas',
      actions: 'Ações',
      estimatedTargetInfo: 'Refere-se o número estimado de PDVs nesse Público.',
      lastUpdateInfo: 'This number is updated daily to reflect the estimated target audience.',
      addNew: 'Criar novo público',
      openExportedFiles: 'Abrir arquivos exportados',
      update: 'Atualizar',
      itemsPerPage: 'Itens por página: {value}',
      name: 'Nome',
      status: 'Status',
      statusProcessing: 'Processando',
      statusNotProcessed: 'Não processado',
      statusProcessedWithErrors: 'Processado com erros',
      statusProcessedSuccess: 'Processado com sucesso',
      created: {
        audienceCreated: 'Público criado!'
      },
      saved: {
        audienceSaved: 'Público salvo!'
      },
      noAudiences: {
        description: 'Seus públicos serão mostrados aqui.',
        btnLabel: 'Criar novo público'
      },
      errorGetAudiences: {
        description: 'Não foi possível carregar a lista. Tente recarregar a página.',
        btnLabel: 'Recarregar lista'
      },
      errorSearchingAudiences: {
        description: 'Nenhum resultado encontrado. Tente alterar sua pesquisa.',
        btnLabel: 'Limpar pesquisa'
      }
    },
    audiencePage: {
      newAudience: 'Novo público',
      manageAudience: 'Gerenciar audiência',
      leaveModal: {
        title: 'Sair da criação de público?',
        body: 'Todas as alterações não salvas serão perdidas',
        leaveButton: 'Sair',
        cancelButton: 'Cancelar'
      },
      vendorModal: {
        title: 'Alterar fornecedor?',
        body: 'Se você alterar o fornecedor, as alterações não salvas serão perdidas.'
      },
      countryModal: {
        title: 'Alterar país?',
        body: 'Se você alterar o país, as alterações não salvas serão perdidas.'
      },
      sellerModal: {
        title: 'Alterar vendedor?',
        body: 'Se você alterar o vendedor, as alterações não salvas serão perdidas.'
      },
      leaveButton: 'Sim, alterar',
      cancelButton: 'Não, voltar',
      exportingFileModal: {
        titleExportingFileModal: 'Exportando arquivo',
        bodyExportingFileModal:
          'Isso pode levar alguns minutos. Vá para arquivos exportados para obter atualizações de status e baixe os arquivos quando estiverem prontos.',
        leaveButtonExportingFileModal: 'Ir para arquivos exportados',
        cancelButtonExportingFileModal: 'Fechar'
      },
      goBackHeaderModal: {
        title: 'Sair desta página?',
        body: 'Todas as alterações não salvas, incluindo arquivos carregados, serão perdidas.',
        leaveButton: 'Sair',
        cancelButton: 'Voltar'
      }
    },
    deleteAudienceModal: {
      title: 'Delete audience?',
      buttonDelete: 'Yes, delete audience',
      buttonCancel: 'No, go back',
      default: 'This action cannot be undone.',
      linked:
        'Unreleased campaigns linked to this audience will appear in an Error state, and you will need to select new audiences for them.'
    },
    exportedFilesPage: {
      title: 'Arquivos exportados',
      table: {
        toolbar: {
          search: 'Pesquisar por nome',
          refresh: 'Atualizar'
        },
        header: {
          audienceName: 'Nome do público',
          type: 'Tipo',
          fileName: 'Nome do arquivo',
          status: 'Status',
          actions: 'Ações'
        },
        columns: {
          types: {
            invalidPocs: 'PDVs inválidos',
            csvAudience: 'Arquivo CSV'
          },
          status: {
            processing: 'Processando',
            done: 'Processado',
            failed: 'Falha'
          }
        }
      }
    }
  },
  actions: {
    edit: 'Editar',
    delete: 'Excluir',
    download: 'Baixar'
  },
  sidebarCMS: {
    Home: 'Home',
    BeesCustomer: 'BEES Customer',
    BeesEngage: 'BEES Engage',
    MiNegocio: 'Mi Negocio',
    AudienceEditor: 'Audience Editor'
  },
  sidebarOffer: {
    OfferList: 'Lista de ofertas',
    AudienceBuilder: 'Criador de audiências'
  },
  useSidebarForce: {
    pageTasks: 'Gestão de Tarefas',
    pageOperations: 'Painel de operações',
    pageTaskGroups: 'Grupos de Tarefas',
    pageAudienceBuilder: 'Criador de audiência'
  },
  sidebarMembership: {
    EarningRules: 'Earning Rules',
    AudienceBuilder: 'Criador de audiências'
  },
  sidebarDeliveryWindow: {
    DdcsList: 'Gerenciar CDDs',
    ClientsList: 'Janelas de Entrega',
    ImportsExports: 'Importações e Exportações',
    Autoblock: 'Parâmetros de disponibilidade de capacidade de entrega',
    Audiences: 'Audiências',
    Seller: 'Configurações do seller'
  }
};

export default ptBR;
