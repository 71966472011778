export interface InitialToggleValue {
  isLoading: boolean;
  isEnabled: boolean | undefined;
}
export enum AudienceEditorToggles {
  audienceCSV = 'audience-by-csv',
  cvsTab = 'tab-csv',
  csvDevValidation = 'audience-dev-csv-validation',
  filtersTab = 'tab-filters',
  popToggleModel = 'pop-toggle-model',
  audienceCsvEditable = 'audience-csv-editable',
  audienceEnableDownloadCsv = 'audience_enable_download_csv',
  audienceDevCheckManufacturerByRoles = 'audience_dev_check_manufacturer_by_roles'
}
