import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useOrigin } from 'context/OriginContext/OriginContext';
import { useEffect, useState } from 'react';
import { getLocalStorageSelectedVendor } from 'utils/LocalStorage/hubHeaderData';
import { OPTIMIZELY_PROJECT_NAME } from '../../constants';
import { AudienceEditorToggles, InitialToggleValue } from './useFeatureToggle.types';

const useFeatureToggle = (toggleName: AudienceEditorToggles) => {
  const initial: InitialToggleValue = { isLoading: false, isEnabled: undefined };

  const [toggle, setToggle] = useState<InitialToggleValue>(initial);

  const userId = useAuthenticationService().getUserId();
  const country = getLocalStorageSelectedVendor().selectedCountry;
  const instanceFT = useFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME);
  const profile = useOrigin();

  useEffect(() => {
    instanceFT.onReady().then(() => {
      setToggle({ ...initial, isLoading: true });

      instanceFT
        .isFeatureEnabledAudiences(toggleName, userId, { country, profile })
        .then((resp) => {
          setToggle({ ...initial, isEnabled: resp, isLoading: false });
        })
        .catch(() => {
          setToggle({ ...initial, isEnabled: false, isLoading: false });
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [toggle.isEnabled, toggle.isLoading];
};

export default useFeatureToggle;
