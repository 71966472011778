import { useHasPermission } from 'admin-portal-shared-services';
import { UseCheckPermissionsForceType } from './useCheckPermissionsForce.type';

export function useCheckPermissionsForce() {
  const hasOperationsReadTaskPermission = useHasPermission([
    UseCheckPermissionsForceType.BeesForceOperationsGlobalRead
  ]);

  const hasVOneCardPermission = useHasPermission([
    UseCheckPermissionsForceType.BeesForceVOneCardRead
  ]);
  const hasVTwoCardPermission = useHasPermission([
    UseCheckPermissionsForceType.BeesForceVTwoCardRead
  ]);
  const hasTaskGroupsPermission = useHasPermission(
    [
      UseCheckPermissionsForceType.BeesForceTaskGroupsRead,
      UseCheckPermissionsForceType.BeesForceTaskGroupsWrite
    ],
    'OR'
  );
  const hasTaskManagementPermission = useHasPermission([
    UseCheckPermissionsForceType.BeesForceTaskManagementWrite
  ]);

  return {
    hasOperationsReadTaskPermission,
    hasVOneCardPermission,
    hasVTwoCardPermission,
    hasTaskGroupsPermission,
    hasTaskManagementPermission
  };
}
