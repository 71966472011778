import { VendorServicesResponse } from 'services/vendors/VendorServices.d';
import { VENDOR_LOCAL_STORAGE } from '../../constants';

export interface LocalStorageVendor extends Omit<VendorServicesResponse, 'code'> {
  selectedVendor: string;
  selectedCountry: string;
  abiVendorId: string;
  allowVendorSelection: boolean;
  isManufacturer: boolean;
}

export interface TierItem {
  name: string;
  app: string;
}

export const EMPTY_VENDOR: LocalStorageVendor = {
  selectedVendor: '',
  selectedCountry: '',
  abiVendorId: '',
  allowVendorSelection: false,
  isManufacturer: false,
  displayName: '',
  serviceModel: '',
  tier: { name: '', app: '' }
};

export const setLocalStorageSelectedVendor = (vendor: LocalStorageVendor) => {
  window.localStorage.setItem(VENDOR_LOCAL_STORAGE, JSON.stringify(vendor));
};

export const getLocalStorageSelectedVendor = (): LocalStorageVendor => {
  const vendor = window.localStorage.getItem(VENDOR_LOCAL_STORAGE);
  return vendor ? JSON.parse(vendor) : EMPTY_VENDOR;
};

export const canDisplaySellerSelector = ({
  roles,
  isManufacturerFromVendor,
  toggle
}: {
  roles: string[];
  isManufacturerFromVendor: boolean;
  toggle: boolean;
}): boolean => {
  if (!toggle || !roles) {
    return isManufacturerFromVendor;
  }

  const hasManufacturer = roles.includes('MANUFACTURER');
  const hasSeller = roles.includes('SELLER');
  if (hasManufacturer && !hasSeller) {
    return true;
  }

  return false;
};
